
import { useStore } from "vuex";
import {
  defineComponent,
  computed,
  ref,
  onMounted,
  reactive,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
export default defineComponent({
  setup() {
    const bankItemList = {
      amount: "",
    };
    const bankNamewatch = ref("");
    const store = useStore();
    const isLoading = ref(false);
    const formInput = reactive({ ...bankItemList });
    const router = useRouter();
    const nextPage = ref(true);
    const dialogVisible = ref(false);
    const dialogVisibleFalse = ref(false);
    const view = ref(true);
    const errMsg = ref("");
    const fillterBank = ref([]);
    const setDefault = ref({});
    const checkSelect = ref(true);
    const bank = computed(() => {
      return store.state.WalletWithdraw.backApprove;
    });
    const setbank = computed(() => {
      return store.state.WalletWithdraw.calWithdraw;
    });
    watch(bankNamewatch, (bankName) => {
      const setSelect = bankName;
      checkSelect.value = false;
      fillterBank.value = bank.value.filter(
        (word) => word.bank_manager_id == setSelect
      );
    });
    watch(dialogVisible, (dialog) => {
      if (dialog == false) {
        router.push({
          path: `/management/wallet-transaction`,
        });
      }
    });
    async function withdraw() {
      errMsg.value = "";
      if (!formInput.amount || !bankNamewatch.value) {
        errMsg.value = "โปรดกรอกข้อมูลให้ถูกต้อง";
      }
      if (parseFloat(formInput.amount) < 2000) {
        errMsg.value = "โปรดกรอกจำนวนเงินมากกว่า 2,000 credit";
      }
      if (!errMsg.value) {
        const form = {
          bank_manager_id: bankNamewatch.value,
          withdraw_price: formInput.amount,
        };
        store.commit("setLoadingGlobal", true);
        let result = await store.dispatch(
          "WalletWithdraw/" + Actions.CAL_WITHDRAW_WALLET,
          { form }
        );
        if (result == false) {
          store.commit("setLoadingGlobal", false);
          dialogVisibleFalse.value = true;
        } else {
          nextPage.value = false;
          store.commit("setLoadingGlobal", false);
        }
      }
    }
    async function confirmWithdraw() {
      const form = {
        bank_manager_id: setbank.value.bank_manager.bank_manager_id,
        withdraw_price: setbank.value.withdraw_price,
        commission_price: setbank.value.commission_price,
        total_price: setbank.value.total_price,
      };
      store.commit("setLoadingGlobal", true);
      let result = await store.dispatch(
        "WalletWithdraw/" + Actions.CREATE_WITHDRAW,
        { form }
      );
      if (result == false) return;
      store.commit("setLoadingGlobal", false);
      dialogVisible.value = true;
    }
    async function clickView() {
      view.value = !view.value;
    }
    async function dialog() {
      dialogVisible.value = false;
      router.push({
        path: `/management/wallet-transaction`,
      });
    }
    async function init() {
      store.commit("setLoadingGlobal", true);
      await store.dispatch("WalletWithdraw/" + Actions.FETCH_BANK_APPROVE);
      const setDefaultbank = bank.value.find(
        (element) => element.bank_manager_default_bank == 1
      );
      bankNamewatch.value = setDefaultbank.bank_manager_id;
      store.commit("setLoadingGlobal", false);
    }
    onMounted(() => {
      init();
    });
    return {
      router,
      nextPage,
      dialogVisible,
      view,
      withdraw,
      confirmWithdraw,
      clickView,
      dialog,
      formInput,
      errMsg,
      bank,
      fillterBank,
      checkSelect,
      bankNamewatch,
      setbank,
      isLoading,
      dialogVisibleFalse,
      setDefault,
    };
  },
});
